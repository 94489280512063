import { Box, Card, CardActionArea, CardContent, Grid, ListItemText, Typography } from "@mui/material"
import React from "react"

import OvcodeRole from "../../../../images/blog/real-time-verification.png";


const RealtimeVerificationComponent = () => {

    return (
        <Box>
            <Card sx={{ cursor: 'pointer', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '18px', width: '90%', margin: 'auto' }}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                    <ListItemText primary={
                                        <React.Fragment>
                                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '2rem' }}>
                                                Maximizing Efficiency with OVCODE's Real-Time Verification
                                            </Typography>
                                        </React.Fragment>
                                    } secondary={
                                        <React.Fragment>
                                            <Typography sx={{ fontSize: '1rem' }}>Tuesday, 02 April  2024</Typography>
                                        </React.Fragment>
                                    } />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center', mt: 1 }}>
                            <img src={OvcodeRole} style={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                In a world where time is of the essence and the pace of digital transactions is ever-increasing, the need for real-time verification solutions has never been more critical.
                                OVCODE is at the forefront of this technological evolution, offering real-time verification services that not only maximize efficiency but also uphold the highest security standards.
                                This breakthrough is transforming industries, enhancing user experiences, and setting new benchmarks for digital interactions.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>The Era of Instantaneity
                            </Typography>
                            <Typography sx={{ mt: 1, mb: 1 , textAlign: 'justify', textJustify: 'inter-word'}}>
                                The digital age has ushered in an era of instantaneity, where users expect immediate results—from data retrieval and processing to transaction verification.
                                The challenge for businesses and platforms is to meet these expectations without compromising on accuracy or security.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>OVCODE’s Real-Time Verification: A Game-Changer
                            </Typography>
                            <Typography sx={{ mt: 1, mb: 1 , textAlign: 'justify', textJustify: 'inter-word'}}>
                                OVCODE's real-time verification technology emerges as a game-changer in this landscape.
                                By leveraging the power of blockchain and advanced cryptographic techniques, OVCODE offers instant verification of transactions, documents, and identities.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Streamlining Operations Across Industries
                            </Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                Whether it’s finance, healthcare, education, or e-commerce, OVCODE’s real-time verification streamlines operations, cutting down wait times and reducing the need for cumbersome manual checks.
                                This efficiency boost not only improves operational workflows but also significantly enhances customer satisfaction.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Enhancing Security with Speed
                            </Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                Speed often comes at the cost of security in digital transactions, but not with OVCODE.
                                The platform ensures that real-time verification does not compromise the integrity or security of the data and transactions being verified.
                                This is achieved through cutting-edge technology that safeguards against tampering and fraud.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Building Trust Through Transparency
                            </Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                Transparency is a cornerstone of trust in digital interactions.
                                OVCODE’s real-time verification provides an immutable record of transactions and interactions, fostering trust between parties.
                                This transparency is vital for businesses looking to establish and maintain credibility in the digital marketplace.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>The Impact: Redefining Efficiency and Trust
                            </Typography>
                            <Typography sx={{ mt: 1, mb: 1 , textAlign: 'justify', textJustify: 'inter-word'}}>
                                The impact of OVCODE's real-time verification is profound, redefining what efficiency means in the digital age.
                                Businesses that adopt this technology can expect not only streamlined operations but also an elevated trust quotient with their users, a critical factor for success in today’s digital landscape.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Conclusion: The Future is Now with OVCODE
                            </Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                OVCODE's real-time verification is not just a tool for enhancing efficiency; it's a paradigm shift in how digital transactions and interactions are conducted.
                                By marrying speed with security and transparency, OVCODE is paving the way for a future where digital transactions are instantaneous, secure, and trusted.
                                The future is now, and with OVCODE, businesses and individuals alike are well-equipped to meet it head-on.
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Grid item xs={12} sx={{ textAlign: 'start', mt: 5, mb: 5 }}>
                <Typography sx={{ color: '#333333', fontWeight: 600, fontSize: '1.25rem' }}>Read Next</Typography>
            </Grid>

            <Grid container spacing={4} sx={{ width: '95%', margin: 'auto' }}>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '14px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-cybergems-future-collecting"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        The Future of Collecting - Blockchain-Certified Cybergems
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        Envision the future of collecting through the lens of OVCODE-certified Cybergems and how this transforms the way we think about ownership and value in the digital age.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ marginTop: 'auto', textAlign: 'start', marginBottom: '5px', fontWeight: 550 }}>
                                May 06, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '14px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-industry-blockchain-synergy"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        OVCODE: Bridging Certified Trust with AATL and Blockchain Synergy
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        OVCODE redefines digital trust by integrating AATL-certified security with the unparalleled verification power of blockchain technology
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ marginTop: 'auto', textAlign: 'start', marginBottom: '5px', fontWeight: 550 }}>
                                April 02, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}

export default RealtimeVerificationComponent