import { Box, Card, CardActionArea, CardContent, Grid, ListItemText, Typography } from "@mui/material"
import React from "react"

import OvcodeDistributor from "../../../../images/blog/ovcode-distributor.png";


const OvcodeDistributorComponent = () => {

    return (
        <Box>
            <Card sx={{ cursor: 'pointer', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '18px', width: '90%', margin: 'auto' }}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                    <ListItemText primary={
                                        <React.Fragment>
                                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '2rem' }}>
                                                OVCODE Distributors Making an Impact: Success Stories
                                            </Typography>
                                        </React.Fragment>
                                    } secondary={
                                        <React.Fragment>
                                            <Typography sx={{ fontSize: '1rem' }}>Tuesday, 16 January 2024</Typography>
                                        </React.Fragment>
                                    } />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center', mt: 1 }}>
                            <img src={OvcodeDistributor} style={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ mt: 1, mb: 1 }}>
                                The OVCODE distributor network plays a pivotal role in bringing cutting-edge digital trust
                                solutions to diverse regions across the globe. Here, we celebrate the success stories of
                                OVCODE distributors and the transformative impact they've had on businesses and
                                individuals in their respective regions.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>1. Empowering Small Businesses in Latin America:</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography><b>Distributor:</b>&nbsp;ABC Tech Solutions, Latin America</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography><b>Success Story:</b></Typography>
                                    <Typography>
                                        ABC Tech Solutions, an OVCODE distributor based in Latin America, has been
                                        instrumental in empowering small businesses to thrive in the digital age. By introducing
                                        OVCODE's eSign solution, they've helped local enterprises streamline their document
                                        workflows, reduce paperwork, and gain a competitive edge. The success stories of
                                        these businesses reflect the positive impact of secure and efficient digital transactions
                                        facilitated by OVCODE.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>2. Revolutionizing Legal Processes in Europe:</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography><b>Distributor:</b>&nbsp;EuroTech Solutions, Europe</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography><b>Success Story:</b></Typography>
                                    <Typography>
                                        EuroTech Solutions, operating as an OVCODE distributor in Europe, has revolutionized
                                        legal processes for law firms and corporate entities. By integrating OVCODE's eMark into
                                        legal documents, they've ensured the integrity and authenticity of critical agreements.
                                        This has not only expedited legal processes but has also bolstered trust in digital
                                        transactions within the European legal landscape.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>3. Enhancing Government Efficiency in Asia:</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography><b>Distributor:</b>&nbsp;Asia Connect Digital, Asia</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography><b>Success Story:</b></Typography>
                                    <Typography>
                                        Asia Connect Digital, an OVCODE distributor in Asia, has made a significant impact on
                                        government efficiency. By implementing OVCODE's eID solution, they've facilitated
                                        secure and seamless identity verification processes. Government agencies across the
                                        region have experienced improved accuracy, reduced fraud, and enhanced citizen
                                        services. The success of these implementations underscores the vital role of OVCODE
                                        distributors in advancing digital transformation on a governmental level.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>4. Safeguarding Artistic Creations in North America:</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography><b>Distributor:</b>&nbsp;ArtGuard Solutions, North America</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography><b>Success Story:</b></Typography>
                                    <Typography>
                                        ArtGuard Solutions, an OVCODE distributor in North America, has played a key role in
                                        safeguarding artistic creations. By introducing eMark to the art community, they've
                                        provided a powerful tool for artists to protect the authenticity and value of their
                                        works. This has not only empowered artists but has also contributed to the
                                        preservation of cultural heritage in the region.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>5. Navigating the Financial Landscape in Africa:</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography><b>Distributor:</b>&nbsp;FinTech Innovators, Africa</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography><b>Success Story:</b></Typography>
                                    <Typography>
                                        FinTech Innovators, an OVCODE distributor in Africa, has navigated the financial
                                        landscape by implementing OVCODE solutions. From secure document transactions
                                        to identity verification in financial services, their impact has been transformative. The
                                        success stories of financial institutions adopting OVCODE technologies highlight the
                                        positive ripple effects on economic growth and financial inclusion in the region.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>6. Facilitating Global Transactions with Cross-Border Distributors:</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography><b>Distributor Network:</b>&nbsp;GlobalLink Distributors</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography><b>Success Story:</b></Typography>
                                    <Typography>
                                        OVCODE's global distributor network, represented by partners like GlobalLink
                                        Distributors, has facilitated seamless and secure global transactions. By fostering
                                        collaborations with local distributors worldwide, OVCODE has created a network that
                                        transcends geographical boundaries. Success stories from this global network
                                        exemplify the adaptability and effectiveness of OVCODE solutions on a truly
                                        international scale.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>


                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Join the OVCODE Distributor Network:</Typography>
                            <Typography>
                                These success stories represent just a glimpse of the impactful work that OVCODE
                                distributors are doing globally. If you're interested in becoming an OVCODE distributor
                                and making a positive impact in your region, join us in shaping the future of digital trust.
                                Together, we can empower businesses and individuals to navigate the digital landscape
                                with confidence and security.
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Grid item xs={12} sx={{ textAlign: 'start', mt: 5, mb: 5 }}>
                <Typography sx={{ color: '#333333', fontWeight: 600, fontSize: '1.25rem' }}>Read Next</Typography>
            </Grid>

            <Grid container spacing={4} sx={{ width: '95%', margin: 'auto' }}>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '14px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-industry-what-to-know"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        Blockchain and Digital Trust Trends: What You Need to Know
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        Explore the latest trends in blockchain technology and their implications for digital trust.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ marginTop: 'auto', textAlign: 'start', marginBottom: '5px', fontWeight: 550 }}>
                                January 16, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '14px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-partner-unlock-growth"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        Unlock Growth: A Guide to Becoming an OVCODE Partner
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        Outline the benefits and steps for individuals or businesses interested in becoming OVCODE partners.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ marginTop: 'auto', textAlign: 'start', marginBottom: '5px', fontWeight: 550 }}>
                                January 16, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}

export default OvcodeDistributorComponent