import { Box, Card, CardActionArea, CardContent, Grid, ListItemText, Typography } from "@mui/material"
import React from "react"

import UnlockingEfficiency from "../../../../images/blog/blockchain-blocked.png";


const BlockchainComponent = () => {

    return (
        <Box>
            <Card sx={{ cursor: 'pointer', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '18px', width: '90%', margin: 'auto' }}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                    <ListItemText primary={
                                        <React.Fragment>
                                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '2rem' }}>
                                                Blockchain-Backed Protection: A Closer Look at eMark's Security Features
                                            </Typography>
                                        </React.Fragment>
                                    } secondary={
                                        <React.Fragment>
                                            <Typography sx={{ fontSize: '1rem' }}>Monday, 15 January 2024</Typography>
                                        </React.Fragment>
                                    } />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center', mt: 1 }}>
                            <img src={UnlockingEfficiency} style={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ mt: 1, mb: 1 }}>
                                In the dynamic landscape of digital content, ensuring the security and integrity of visual assets
                                is a complex challenge. OVCODE's revolutionary solution, eMark, takes on this challenge by
                                leveraging the power of blockchain technology. Let's explore the technical aspects of how
                                eMark utilizes blockchain for enhanced security.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>1. Blockchain Immutability:</Typography>
                            <Typography>
                                At the core of eMark's security is the immutability of the blockchain. Once an eMark is embedded
                                into visual content and recorded on the blockchain, it becomes an indelible part of the ledger. Any
                                attempt at tampering is immediately detectable, ensuring the integrity of the visual asset.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>2. Cryptographic Hashing:</Typography>
                            <Typography>
                                eMark employs advanced cryptographic hashing techniques to create a unique digital fingerprint
                                for each visual asset. This fingerprint is derived from the content itself, making it specific to the
                                characteristics of the image, photo, or video. The hash is then securely stored on the blockchain.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>3. Smart Contracts for Authentication:</Typography>
                            <Typography>
                                Smart contracts play a pivotal role in eMark's security architecture. These self-executing contracts
                                are programmed to execute predefined actions when specific conditions are met. In the case of
                                eMark, smart contracts automate the authentication process, ensuring that only legitimate eMarks
                                are recorded on the blockchain.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>4. Decentralized Storage:</Typography>
                            <Typography>
                                The eMark information, including the cryptographic hash and authentication details, is stored in a
                                decentralized manner across the blockchain network. This decentralized storage eliminates the
                                vulnerabilities associated with centralized systems, as there is no single point of failure.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>5. Cross-Blockchain Compatibility:</Typography>
                            <Typography>
                                eMark is designed for versatility and interoperability. It supports various blockchain platforms
                                such as EOS, IPFS, and Polygon. This cross-blockchain compatibility ensures that eMark users
                                have flexibility in choosing the blockchain infrastructure that aligns with their specific needs and
                                preferences.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>6. Timestamping for Chronological Order:</Typography>
                            <Typography>
                                Timestamping is a crucial component of eMark's security. Each eMark is associated with a
                                timestamp that signifies the exact moment it was embedded into the visual content. This
                                chronological order is recorded on the blockchain, providing an immutable timeline of
                                authenticity.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>7. Public and Transparent Ledger:</Typography>
                            <Typography>
                                The transparency of the blockchain is a key feature that enhances eMark's security. The eMark
                                ledger is public and accessible to all participants in the network. This transparency not only
                                fosters trust but also serves as a deterrent against any attempts at malicious activities.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>8.  Authentication through Private Keys:</Typography>
                            <Typography>
                                To ensure secure and authorized access to eMark information, authentication is carried out
                                through private keys. Only individuals or entities with the corresponding private keys have the
                                authority to interact with and verify eMarks on the blockchain.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Conclusion</Typography>
                            <Typography>
                                The technical prowess of eMark lies in its innovative combination of blockchain principles
                                and cryptographic techniques. By utilizing the inherent strengths of blockchain—
                                immutability, decentralization, and transparency—eMark creates a secure and tamper-proof
                                environment for visual content. As businesses and creators navigate the digital landscape,
                                eMark stands as a testament to the transformative power of blockchain-backed protection.
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Grid item xs={12} sx={{ textAlign: 'start', mt: 5, mb: 5 }}>
                <Typography sx={{ color: '#333333', fontWeight: 600, fontSize: '1.25rem' }}>Read Next</Typography>
            </Grid>

            <Grid container spacing={4} sx={{ width: '95%', margin: 'auto' }}>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '10px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-support-navigating-ovcode"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        Navigating OVCODE: A Comprehensive Guide to User Support
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        Provide an overview of the support services offered by OVCODE and guide users on how to access assistance.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ marginTop: 'auto', textAlign: 'start', marginBottom: '5px', fontWeight: 550 }}>
                                January 15, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '10px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-emark-visual-content"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        Visual Content Security: How eMark Protects Your Digital Assets
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        Explain the significance of eMark in protecting visual content and preventing tampering.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ textAlign: 'start', marginTop: 'auto', marginBottom: '5px', fontWeight: 550 }}>
                                January 15, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box >
    )
}

export default BlockchainComponent