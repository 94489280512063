import { Box, Card, CardActionArea, CardContent, Grid, ListItemText, Typography } from "@mui/material"
import React from "react"

import IdentitiyVerification from "../../../../images/blog/secure-payments.png";


const SecurePaymentsComponent = () => {

    return (
        <Box>
            <Card sx={{ cursor: 'pointer', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '18px', width: '90%', margin: 'auto' }}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                    <ListItemText primary={
                                        <React.Fragment>
                                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '2rem' }}>
                                                Revolutionizing Secure Payments: <br/> Enhancing User Experience with OVCODE's eID Verification 
                                            </Typography>
                                        </React.Fragment>
                                    } secondary={
                                        <React.Fragment>
                                            <Typography sx={{ fontSize: '1rem' }}>Tuesday, 02 April 2024</Typography>
                                        </React.Fragment>
                                    } />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center', mt: 1 }}>
                            <img src={IdentitiyVerification} style={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                            In the digital age, secure and efficient payment systems are not just a convenience but a necessity. 
                            As e-commerce and digital transactions continue to grow, so do the challenges of ensuring these payments are both secure and user-friendly. 
                            Enter OVCODE, a revolutionary platform using eID verification to transform how secure payments are made, enhancing the user experience without compromising security. 
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3}}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>The Challenge: Balancing Security with Convenience </Typography>
                            <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                One of the most significant challenges facing online payments is finding the right balance between stringent security measures and a seamless user experience. 
                                Overly complex security can deter users, leading to abandoned carts and lost sales, while simplified processes, on the other hand, can make systems vulnerable to fraud. 
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>OVCODE's Solution: Seamless eID Verification</Typography>
                            <Typography sx={{textAlign: 'justify', textJustify: 'inter-word',  wordSpacing: '2.5px'}}>
                                OVCODE's eID verification system is designed to address this challenge head-on, offering a solution that is both secure and <br/>  user-friendly. 
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Streamlining Authentication </Typography>
                            <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                OVCODE's eID technology streamlines the authentication process, allowing users to verify their identity quickly and securely. 
                                This process not only speeds up transactions but also significantly reduces the risk of fraud, providing a seamless experience for users and peace of mind for businesses. 
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Enhancing User Experience </Typography>
                            <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                            By integrating OVCODE's eID verification, online platforms can offer a smoother checkout process. 
                            Users can enjoy the convenience of fast, secure transactions without the hassle of repetitive verification steps, improving overall satisfaction and loyalty. 
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Building Trust with Advanced Security </Typography>
                            <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                            At the core of OVCODE's eID system is advanced security technology that protects users' identities and financial information. 
                            This level of security builds trust, ensuring that users feel confident in the safety of their online transactions. 
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>The Impact: A New Era of Secure Digital Payments </Typography>
                            <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                            The implementation of OVCODE's eID verification marks the beginning of a new era in digital payments. 
                            Businesses adopting this technology can expect reduced fraud, increased transaction speeds, and higher customer satisfaction. 
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Conclusion: Transforming Transactions with OVCODE</Typography>
                            <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                            As we move forward in the digital economy, the need for secure and efficient payment systems becomes ever more critical. 
                            OVCODE's innovative eID verification system meets this need, offering a revolutionary solution that enhances the user experience while ensuring the highest security standards. 
                            In doing so, OVCODE is setting the stage for a future where secure and seamless transactions are the norm, and not the exception.
                            </Typography>
                        </Grid>
                       </Grid>
                </CardContent>
            </Card>

            <Grid item xs={12} sx={{ textAlign: 'start', mt: 5, mb: 5 }}>
                <Typography sx={{ color: '#333333', fontWeight: 600, fontSize: '1.25rem' }}>Read Next</Typography>
            </Grid>

            <Grid container spacing={4} sx={{ width: '95%', margin: 'auto' }}>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '10px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-eid-identity-verification"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        The Future of Identity Verification: An In-Depth Look at OVCODE e
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        Discuss the challenges in traditional identity verification and how OVCODE eID revolutionizes the process.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ textAlign: 'start', marginTop: 'auto', marginBottom: '5px', fontWeight: 550 }}>
                                January 15, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card 
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '10px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-eid-success-stories"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        eID Success Stories: Real-world Applications of Secure Identity Verification
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        Share case studies and success stories of businesses benefiting from OVCODE eID.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ textAlign: 'start', marginTop: 'auto', marginBottom: '5px', fontWeight: 550 }}>
                                January 15, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}

export default SecurePaymentsComponent