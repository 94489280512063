import { Box, Card, CardActionArea, CardContent, Grid, ListItemText, Typography } from "@mui/material"
import React from "react"

import UnlockGrowth from "../../../../images/blog/unlock-growth.png";


const UnlockGrowthComponent = () => {

    return (
        <Box>
            <Card sx={{ cursor: 'pointer', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '18px', width: '90%', margin: 'auto' }}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                    <ListItemText primary={
                                        <React.Fragment>
                                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '2rem' }}>
                                                Unlock Growth: A Guide to Becoming an OVCODE Partner
                                            </Typography>
                                        </React.Fragment>
                                    } secondary={
                                        <React.Fragment>
                                            <Typography sx={{ fontSize: '1rem' }}>Tuesday, 16 January 2024</Typography>
                                        </React.Fragment>
                                    } />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center', mt: 1 }}>
                            <img src={UnlockGrowth} style={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ mt: 1, mb: 1 }}>
                                At OVCODE, we believe in the power of partnerships to drive innovation and unlock new
                                opportunities. Whether you're an individual entrepreneur or a business looking to expand,
                                becoming an OVCODE partner opens the door to a world of benefits and collaborative
                                growth. Here's a guide to help you embark on your journey as an OVCODE partner.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>1. Benefits of Becoming an OVCODE Partner:</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontWeight: 'bold' }}>a. Cutting-Edge Solutions:</Typography>
                                    <Typography>
                                        As an OVCODE partner, you gain access to our cutting-edge solutions, including eSign, eID,
                                        eMark, and more. Integrate these innovative tools into your offerings to enhance your value
                                        proposition.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                                    <Typography sx={{ fontWeight: 'bold' }}>b. Business Growth Opportunities:</Typography>
                                    <Typography>
                                        Tap into new business growth opportunities by aligning with OVCODE's vision for a secure
                                        and efficient digital future. Leverage our solutions to address the evolving needs of your
                                        clients and customers.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontWeight: 'bold' }}>c. Marketing and Brand Exposure:</Typography>
                                    <Typography>
                                        Benefit from co-marketing initiatives and brand exposure as an official OVCODE partner.
                                        Showcase your partnership to a global audience and leverage OVCODE's marketing channels
                                        to amplify your reach.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontWeight: 'bold' }}>d. Dedicated Support and Training:</Typography>
                                    <Typography>
                                        Receive dedicated support and training to ensure a smooth integration of OVCODE solutions
                                        into your business operations. Our team is committed to helping partners succeed every step
                                        of the way.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>2. Steps to Become an OVCODE Partner:</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontWeight: 'bold' }}>a. Partner Application:</Typography>
                                    <Typography>
                                        Start by submitting a partner application through the OVCODE website. Provide details about
                                        your business, industry, and how you envision integrating OVCODE solutions into your offerings.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                                    <Typography sx={{ fontWeight: 'bold' }}>b. Needs Assessment:</Typography>
                                    <Typography>
                                        Following your application, our team will conduct a needs assessment to understand your
                                        specific requirements and tailor the partnership to suit your business objectives.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontWeight: 'bold' }}>c. Agreement and Onboarding:</Typography>
                                    <Typography>
                                        Upon approval, you'll enter into a partnership agreement with OVCODE. Our onboarding
                                        process includes comprehensive training to ensure you have the knowledge and tools to
                                        successfully integrate and promote OVCODE solutions.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontWeight: 'bold' }}>d. Integration Support:</Typography>
                                    <Typography>
                                        Receive hands-on integration support from our technical team. Whether you're embedding
                                        eSign into your platform or utilizing eMark for content protection, our experts will guide you
                                        through the process.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontWeight: 'bold' }}>e. Marketing Collaboration:</Typography>
                                    <Typography>
                                        Collaborate with OVCODE on marketing initiatives. Access co-branded materials, participate
                                        in joint campaigns, and leverage our marketing channels to showcase the value of your
                                        partnership.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontWeight: 'bold' }}>f. Ongoing Support and Updates:</Typography>
                                    <Typography>
                                        As a partner, you'll receive ongoing support, updates, and access to new features and
                                        solutions as they are released. Our commitment is to ensure your continued success within
                                        the OVCODE ecosystem.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>3. Join the OVCODE Partner Network:</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontWeight: 'bold' }}>a. Explore Partnership Tiers:</Typography>
                                    <Typography>
                                        Explore the different partnership tiers available based on your business size, industry, and
                                        level of integration. From reseller partnerships to technology integrations, there's a tier that
                                        suits your goals.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                                    <Typography sx={{ fontWeight: 'bold' }}>b. Network with Other Partners:</Typography>
                                    <Typography>
                                        Join the OVCODE Partner Network to connect with other partners, share insights, and explore
                                        collaborative opportunities. The network is a vibrant community where ideas are exchanged,
                                        and partnerships are forged.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                                    <Typography sx={{ fontWeight: 'bold' }}>c. Attend Partner Events:</Typography>
                                    <Typography>
                                        Participate in partner-exclusive events, webinars, and conferences. Stay informed about
                                        industry trends, OVCODE updates, and collaborative opportunities to further enhance your
                                        partnership.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>4. Begin Your Journey with OVCODE:</Typography>
                            <Typography>
                                Becoming an OVCODE partner is not just a collaboration; it's a journey toward mutual growth
                                and success. Join us in shaping a future where digital trust is paramount, and together, let's
                                unlock new possibilities for innovation and efficiency.
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Grid item xs={12} sx={{ textAlign: 'start', mt: 5, mb: 5 }}>
                <Typography sx={{ color: '#333333', fontWeight: 600, fontSize: '1.25rem' }}>Read Next</Typography>
            </Grid>

            <Grid container spacing={4} sx={{ width: '95%', margin: 'auto' }}>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '10px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-partner-ovcode-distributor"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        OVCODE Distributors Making an Impact: Success Stories
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        Highlight the success stories of OVCODE distributors and the impact they've made in various regions.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ textAlign: 'start', marginTop: 'auto', marginBottom: '5px', fontWeight: 550 }}>
                                January 16, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '14px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-industry-what-to-know"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        Blockchain and Digital Trust Trends: What You Need to Know
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        Explore the latest trends in blockchain technology and their implications for digital trust.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ marginTop: 'auto', textAlign: 'start', marginBottom: '5px', fontWeight: 550 }}>
                                January 16, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}

export default UnlockGrowthComponent