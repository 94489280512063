import { Box, Card, CardActionArea, CardContent, Grid, ListItemText, Typography } from "@mui/material"
import React from "react"

import OvcodeRole from "../../../../images/blog/blockchain-min.png";


const BlockchainSynergyComponent = () => {

    return (
        <Box>
            <Card sx={{ cursor: 'pointer', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '18px', width: '90%', margin: 'auto' }}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                    <ListItemText primary={
                                        <React.Fragment>
                                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '2rem' }}>
                                                OVCODE: Bridging Certified Trust with AATL <br /> and Blockchain Synergy
                                            </Typography>
                                        </React.Fragment>
                                    } secondary={
                                        <React.Fragment>
                                            <Typography sx={{ fontSize: '1rem' }}>Tuesday, 02 April  2024</Typography>
                                        </React.Fragment>
                                    } />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center', mt: 1 }}>
                            <img src={OvcodeRole} style={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                In a world that's rapidly digitizing, the demand for security within the realms of document signing and verification is more critical than ever.
                                While AATL (Adobe Approved Trust List) provides a robust certification process, OVCODE amplifies trust and security by synergizing AATL standards with blockchain technology’s innate strengths.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Elevating Trust with AATL’s Rigorous Standards
                            </Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                The AATL certification is the benchmark for trusted digital signatures and documents within the Adobe ecosystem.
                                As an endorser of these rigorous standards, OVCODE taps into a well-established trust framework that is recognized across industries and around the globe.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Harnessing Blockchain’s Decentralized Power
                            </Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                By integrating blockchain technology, OVCODE goes beyond conventional trust mechanisms.
                                Blockchain's decentralized nature provides a transparent, tamper-evident environment where each digital signature or document is verifiable and immutable.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Strengthening Document Security and Integrity
                            </Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                Combining AATL’s certified trust with blockchain’s immutability, OVCODE creates a digital fortress around documents.
                                This synergy ensures that every signed document upholds the highest integrity, from its creation to the multiple echelons of its lifecycle.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Streamlining Processes for Global Acceptance
                            </Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                OVCODE’s integration paves the way for smoother processes and global acceptance of digital signatures.
                                It offers organizations the ability to operate within a trusted digital framework that’s not only compliant with AATL's standards but also fortified by the security and efficiency of blockchain technology.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>The Impact: Reimagining Digital Workflows
                            </Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                The confluence of AATL certification and blockchain within OVCODE's platform is transforming digital workflows.
                                It is making transactions more secure, verification processes faster, and cross-border document exchanges seamless.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Conclusion: A New Era of Digital Trust
                            </Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                OVCODE is pioneering a new era of digital trust by bridging certified trust with blockchain synergy.
                                Organizations using OVCODE can operate with confidence, knowing they are at the forefront of secure, efficient, and trusted digital transactions, today and into the future.
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Grid item xs={12} sx={{ textAlign: 'start', mt: 5, mb: 5 }}>
                <Typography sx={{ color: '#333333', fontWeight: 600, fontSize: '1.25rem' }}>Read Next</Typography>
            </Grid>

            <Grid container spacing={4} sx={{ width: '95%', margin: 'auto' }}>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '14px', display: 'flex', flexDirection: 'column' }}
                    // onClick={() => window.location.href = ""}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        Crafting Trust in the Blockchain - OVCODE's Role in NFT Integrity
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        Delve into the concept of trust in the blockchain ecosystem and how OVCODE’s robust authentication protocols are critical for maintaining the integrity of NFTs like Cybergems.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ marginTop: 'auto', textAlign: 'start', marginBottom: '5px', fontWeight: 550 }}>
                                May 07, 2024
                            </Typography>
                        </CardContent>
                    </Card>

                </Grid>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '14px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-industry-building-trust"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        Building Trust in the Cloud Era with Technology Certified by AATL Members
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        In the fast-evolving cloud computing landscape, OVCODE's use of technology certified by members of the Adobe Approved Trust List (AATL).
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ marginTop: 'auto', textAlign: 'start', marginBottom: '5px', fontWeight: 550 }}>
                                April 02, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}

export default BlockchainSynergyComponent